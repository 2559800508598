<template>
  <v-flex style="background: #fff; min-height: 100vh;">
    <v-bottom-navigation
      v-model="menu_bottom"
      :value="menu_bottom"
      color="#555555"
      grow
      class="elevation-0"
      style="border-top-right-radius: 20px;border-top-left-radius: 20px;"
      background-color="#F5F5F5"
      app
    >
      <v-btn>
        <span class="fonte">Início</span>

        <v-icon>mdi-home-heart</v-icon>
      </v-btn>

      <v-btn>
        <span class="fonte">Busca</span>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn>
        <span class="fonte">Pedidos</span>
        <v-icon>mdi-file-document-outline</v-icon>
      </v-btn>

      <v-btn>
        <span class="fonte">Perfil</span>
        <img
          class="mb-1"
          src="https://firebasestorage.googleapis.com/v0/b/menu-rocket.appspot.com/o/user-alt-1%201.png?alt=media&token=64aa3c83-c6b5-4ab0-be6b-cd453a787d01"
          alt=""
        />
      </v-btn>
    </v-bottom-navigation>
    <div class="expande-horizontal wrap" v-if="menu_bottom === 0">
      <div class="expande-horizontal wrap px-6 mb-3">
        <v-flex class="mb-3" xs12>
          <div class="expande-horizontal">
            <v-spacer></v-spacer>
            <v-btn color="#8F8989" icon>
              <v-icon :color="$theme.icon_color_secondary"
                >mdi-qrcode-scan</v-icon
              >
            </v-btn>
          </div>
        </v-flex>
        <v-flex xs12>
          <div class="expande-horizontal">
            <v-list dense color="transparent">
              <v-list-item>
                <v-avatar
                  class="mr-3"
                  size="40"
                  :color="$theme.icon_color_secondary"
                >
                  <v-icon color="white" size="19">mdi-map-marker</v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="fonte font-color">
                    Endereço
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonte">
                    Cadastre um endereço aqui
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-flex>
        <!-- <v-flex xs12>
          <div class="expande-horizontal">
            <v-btn icon small class="mt-1 mr-3">
              <v-icon color="#38ACBA">mdi-menu</v-icon>
            </v-btn>
            <v-text-field
              class="fonte"
              append-icon="mdi-magnify"
              color="#38ACBA"
              placeholder="O que você está procurando?"
              solo-inverted
              dense
              hide-details
              flat
              rounded
            ></v-text-field>
          </div>
        </v-flex> -->
      </div>

      <div class="expande-horizontal centraliza pa-3 pr-6 pl-6">
        <v-img
          style="width: 100%"
          contain
          class=""
          src="@/apps/shared/assets/banner-group.svg"
        ></v-img>
      </div>

      <div class="expande-horizontal pl-6 wrap mb-3">
        <v-flex class="mb-3 mt-3" xs12>
          <div class="expande-horizontal centraliza pr-6">
            <v-btn text>
              <span
                class="text-capitalize fonte font-color fonte-micro font-weight-bold"
                >Favoritos</span
              >
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :color="$theme.icon_color_secondary" outlined icon small>
              <v-icon color="#555555">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-flex>
        <!-- FAVORITOS -->
        <v-flex xs12>
          <div class="expande-horizontal">
            <v-slide-group>
              <v-slide-item
                v-show="i < 4"
                v-for="(market, i) in get_marketplaces"
                :key="market._id"
              >
                <div style="width: 93px;">
                  <v-card
                    dark
                    @click="$router.push(`/loja/${market._id}`)"
                    class="ml-3 pa-0 elevation-0 centraliza"
                    style="border-radius: 21px; filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));"
                    width="76"
                    height="76"
                  >
                    <v-img
                      style="height: 100%; width: 100%;"
                      contain
                      :src="
                        market.photos[0] ||
                          'https://www.adobe.com/br/express/create/media_136a9ef98b28654104e97cb59658318f3432395db.jpeg?width=400&format=jpeg&optimize=medium'
                      "
                    ></v-img>
                  </v-card>
                  <v-list-item
                    @click="$router.push(`/loja/${market._id}`)"
                    class="mb-0 pb-0"
                  >
                    <small class="fonte fonte-micro">
                      {{ market.nome }}
                    </small>
                  </v-list-item>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-flex>
        <!-- Para você -->
        <v-flex class="mb-3 mt-9" xs12>
          <div class="expande-horizontal centraliza pr-6">
            <v-btn text>
              <span
                class="text-capitalize fonte font-color fonte-micro font-weight-bold"
                >Para você</span
              >
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :color="$theme.icon_color_secondary" outlined icon small>
              <v-icon color="#555555">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-flex>
        <v-flex xs12>
          <div class="expande-horizontal">
            <v-slide-group>
              <v-slide-item
                v-show="i < 4"
                v-for="(market, i) in get_marketplaces"
                :key="market._id"
              >
                <div style="width: 93px;">
                  <v-card
                    dark
                    @click="$router.push(`/loja/${market._id}`)"
                    class="ml-3 pa-0 elevation-0 centraliza"
                    style="border-radius: 21px; filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));"
                    width="76"
                    height="76"
                  >
                    <v-img
                      style="height: 100%; width: 100%;"
                      contain
                      :src="
                        market.photos[0] ||
                          'https://www.adobe.com/br/express/create/media_136a9ef98b28654104e97cb59658318f3432395db.jpeg?width=400&format=jpeg&optimize=medium'
                      "
                    ></v-img>
                  </v-card>
                  <v-list-item
                    @click="$router.push(`/loja/${market._id}`)"
                    class="mb-0 pb-0"
                  >
                    <small
                      style="max-width: 10px;height: 30px;"
                      class="fonte fonte-micro"
                    >
                      {{ market.nome }}
                    </small>
                  </v-list-item>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-flex>
        <!-- entrega grátis -->
        <v-flex class="mb-3 mt-9" xs12>
          <div class="expande-horizontal centraliza pr-6">
            <v-btn text>
              <span
                class="text-capitalize fonte font-color fonte-micro font-weight-bold"
                >Entrega Grátis</span
              >
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :color="$theme.icon_color_secondary" outlined icon small>
              <v-icon color="#555555">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-flex>
        <v-flex xs12>
          <div class="expande-horizontal">
            <v-slide-group>
              <v-slide-item
                v-show="i < 4"
                v-for="(market, i) in get_marketplaces"
                :key="market._id"
              >
                <div style="width: 93px;">
                  <v-card
                    dark
                    @click="$router.push(`/loja/${market._id}`)"
                    class="ml-3 pa-0 elevation-0 centraliza"
                    style="border-radius: 21px; filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));"
                    width="76"
                    height="76"
                  >
                    <v-img
                      style="height: 100%; width: 100%;"
                      contain
                      :src="
                        market.photos[0] ||
                          'https://www.adobe.com/br/express/create/media_136a9ef98b28654104e97cb59658318f3432395db.jpeg?width=400&format=jpeg&optimize=medium'
                      "
                    ></v-img>
                  </v-card>
                  <v-list-item
                    @click="$router.push(`/loja/${market._id}`)"
                    class="mb-0 pb-0"
                  >
                    <small class="fonte fonte-micro">
                      {{ market.nome }}
                    </small>
                  </v-list-item>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-flex>
      </div>
    </div>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Signup from "@/apps/2#auth/components/FormSignup";
import Login from "@/apps/2#auth/components/FormLogin";

export default {
  data() {
    return {
      menu_bottom: 0,
      cord: false
    };
  },
  components: {
    Signup,
    Login
  },
  computed: {
    ...mapGetters(["get_marketplaces", "get_marketplaces_filtros"])
  },
  methods: {
    ...mapActions(["abre_modal_view_marketplace", "createConfirmAction"])
  },
  created() {
    if (cordova) {
      this.cord = true;
    }
  }
};
</script>

<style scoped>
.font-color {
  color: #555555;
}
</style>
