<template>
  <div class="expande-horizontal wrap pa-3">
    <v-flex xs12 class="pr-2">
      <v-text-field
        dense
        solo
        background-color="#f2f2f2"
        small
        flat
        label="Filtrar por nome"
        v-model="get_marketplaces_filtros.nome"
        clearable
        hide-details
        :color="$theme.primary"
        @input="debounceSearch"
      ></v-text-field>
    </v-flex>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
var _ = require("lodash");
export default {
  name: "Filtros",
  computed: {
    ...mapGetters(["get_marketplaces", "get_marketplaces_filtros"])
  },
  methods: {
    ...mapActions(["listar_marketplaces"]),
    debounceSearch() {
      const x = _.debounce(this.listar_marketplaces, 800);
      x();
    }
  }
};
</script>
