<template>
  <v-form class="expande-horizontal wrap pa-3 px-6 centraliza" ref="form">
    <v-flex class="py-1 pt-6" xs12 md10>
      <div class="expande-horizontal centraliza">
        <img
          height="50"
          src="https://firebasestorage.googleapis.com/v0/b/menu-rocket.appspot.com/o/Layer_x0020_1.png?alt=media&token=1bfb47d7-4c5d-467b-ba44-7b25813cff63"
          alt=""
        />
        <!-- <img height="30" :src="$theme.logo" alt="" /> -->
      </div>
    </v-flex>

    <v-flex class="py-6 mt-6" xs12 md10>
      <div class=" pt-6 expande-horizontal centraliza">
        <span class="fonte text-center grey--text"
          >Faça Login para acessar o sistema!</span
        >
      </div>
    </v-flex>

    <v-flex xs12 md8>
      <v-text-field
        rounded
        solo-inverted
        flat
        label="Login"
        class="fonte"
        clearable
        :color="$theme.primary"
        :rules="[v => !!v || 'Preencha este campo']"
        v-model="payload.username"
      ></v-text-field>
    </v-flex>

    <v-flex xs12 md8>
      <v-text-field
        rounded
        solo-inverted
        flat
        clearable
        label="Senha"
        class="fonte"
        @keyup.enter="validaCampo"
        type="password"
        @click="validaCampo"
        :color="$theme.primary"
        :rules="[v => !!v || 'Preencha este campo']"
        v-model="payload.senha"
      ></v-text-field>
    </v-flex>

    <v-flex class="mb-3" xs12 md8>
      <div class="expande-horizontal">
        <v-btn text @click="setModalForgotPassword(true)" small>
          <span class="fonte text-capitalize fonte grey--text"
            >Esqueceu sua Senha?</span
          >
        </v-btn>
      </div>
    </v-flex>

    <v-flex xs12 md10>
      <div class="expande-horizontal centraliza">
        <v-btn
          class="text-capitalize elevation-0 fonte"
          rounded
          dark
          block
          color="#38ACBA"
          @click="validaCampo"
          >Login</v-btn
        >
      </div>
    </v-flex>
    <ForgotPassword />
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import ForgotPassword from "./ForgotPassword";
export default {
  data() {
    return {
      payload: {
        username: "",
        senha: ""
      }
    };
  },
  components: {
    ForgotPassword
  },
  methods: {
    ...mapActions(["createSession", "setModalForgotPassword"]),
    validaCampo() {
      if (this.$refs.form.validate()) {
        this.createSession(this.payload);
      }
    }
  }
};
</script>
