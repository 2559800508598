<template lang="pug">
    div.flex.horizontal-expand.horizontal-centralize.vertical-centralize()
        div.flex.horizontal-expand.expand-vh-vertical(style="min-height: 100vh; background-color: #e5e5e5; display: flex; width: 100%; justify-content: center;")
          v-flex.pa-3(xs12 md3 v-if="passo===1")
              div.expande-horizontal.centraliza.horizontal-vh-expand.column
                div.flex.expande-horizontal
                  v-btn(text @click="$router.go(-1)" class="elevation-0" rounded outlined color="white")
                    v-icon(size="13") mdi-chevron-left
                    span.white--text.fonte.text-capitalize.white--text Login
                v-flex(xs12).mt-12
                  div.expande-horizontal.centraliza
                    img(:src="$theme.logo")
                v-flex(xs12).mt-12.pa-6
                  div.expande-horizontal.centraliza.column
                    span.fonte.text-center.white--text.white--text Bem Vindo(a), veja seu patrocinanor abaixo.
                    
                    v-list(dark dense rounded nav width="270")
                        v-list-item
                            v-avatar.mr-1(color="transparent" classe="elevation-1")
                                v-icon() mdi-account-circle
                            v-list-item-content
                                v-list-item-title(class="fonte")
                                    h2.fonte.font-weight-light {{ getSponsor.name }}
                            v-list-item-actions
                                v-icon(color="green accent-3") mdi-check

                    v-btn.fonte.mt-12(@click="passo=2" :color="$theme.primary" rounded)
                      span.fonte.white--text Continuar Cadastro
                      v-icon(size="18") mdi-chevron-right 
          v-flex.pa-3(xs12 md3 v-if="passo===2")
              v-form(ref="form")
                  div.flex.horizontal-expand.horizontal-centralize.vertical-centralize.column
                      div.flex.expande-horizontal
                        v-btn(icon @click="$router.go(-1)" class="elevation-1" color="black")
                          v-icon mdi-chevron-left
                      div.flex.expande-horizontal.centraliza
                        img(height="120" :src="$theme.logo")
                      div.expande-horizontal.centraliza
                        span.margin-vertical.mb-6.fonte.text-center.mt-6.pr-6.pl-6.font-weight-thin.white--text Faça seu cadastro abaixo.

                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text Nome
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.name"
                                  placeholder="ex: Marinaldo"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text Nickname (usado para login e indicações)
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.nickname"
                                  placeholder="ex: naldo"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text E-mail
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.email"
                                  placeholder="ex: meu_melhor_email@gmail.com"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text CPF
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.cpf"
                                  placeholder="ex: 000.000.000-00"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  v-mask="['###.###.###-##']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text Um celular para contato
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.phone"
                                  placeholder="ex: (96) 98113-1234"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  v-mask="['(##) # #### ####']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text Senha
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.password"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  placeholder="******"
                                  type="password"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.mb-6.flex.horizontal-expand.column
                          v-btn.fonte(
                              @click="$router.go(-1)"
                              small
                              text
                              block
                              dark
                              :color="$theme.primary"
                          )
                            span.white--text Já tem cadastro? Faça Login

                      div.mb-6.expande-horizontal.centraliza
                          v-btn.fonte(
                              rounded
                              @click="valida_form"
                              :color="$theme.primary"
                          ) 
                            span.fonte Finalizar e Cadastrar

                      //- h3.margin-vertical.mb-12.fonte.text-center Ao criar uma conta, você concorda com a nossa Política de Privacidade.

                
               

</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["sponsor_id"],
  data() {
    return {
      passo: 1,
      modal: false,
      payload: {
        sponsor: {
          _id: "nosponsor"
        }
      }
    };
  },
  computed: {
    ...mapGetters(["getSponsor"])
  },
  methods: {
    ...mapActions(["createUser", "verifySponsor"]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.createUser(this.payload);
      }
    }
  },
  async created() {
    if (this.sponsor_id === "nosponsor") {
      this.passo = 2;
      this.payload.sponsor_id = "nosponsor";
    } else {
      this.payload.sponsor = await this.verifySponsor(this.sponsor_id);
      this.payload.sponsor_id = this.sponsor_id;
    }
  }
};
</script>
