<template>
  <v-pagination
    outlined
    style="background: #E5E5E5;"
    :color="$theme.primary"
    total-visible="7"
    v-model="get_marketplaces_filtros.page"
    :length="get_marketplaces.totalPages"
    @input="setPage($event)"
  ></v-pagination>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_marketplaces", "get_marketplaces_filtros"])
  },
  methods: {
    ...mapActions(["listar_marketplaces"]),
    setPage(page) {
      if (page) {
        console.log("page", page);
        this.get_marketplaces_filtros.page = page;
        this.listar_marketplaces();
      }
    }
  }
};
</script>
