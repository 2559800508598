<template>
  <div class="expande-horizontal wrap">
    <VisualizacaoEmLista />
  </div>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import VisualizacaoEmCard from "../components/VisualizacaoEmCard.vue";
import VisualizacaoEmLista from "../components/VisualizacaoEmLista.vue";
import Paginacao from "../components/Paginacao.vue";
import ListagemVazia from "../components/ListagemVazia.vue";
import ModalView from "../components/modalView.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      card: false,
      options: []
    };
  },
  components: {
    Filtros,
    VisualizacaoEmCard,
    VisualizacaoEmLista,
    Paginacao,
    ListagemVazia,
    ModalView
  },
  computed: {
    ...mapGetters([
      "get_marketplaces",
      "get_marketplaces_filtros",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions(["abre_modal_view_marketplace", "listar_marketplaces"]),
    imprimir() {
      this.card = false;
      this.$refs.html2Pdf.generatePdf();
    }
  },
  created() {
    this.listar_marketplaces();
    StatusBar.backgroundColorByHexString("#38ACBA");
  }
};
</script>
